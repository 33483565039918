.App {
  /* Full-screen */
  height: 100%;
  /* Center the background image */
  background-position: center;
  /* Scale and zoom in the image */
  background-size: cover;
  /* Add position: relative to enable absolutely positioned elements inside the image (place text) */
  position: relative;
  /* Add a font */
  /* Set the font-size to 25 pixels */
  font-size: 25px;
  text-align: center;
}

.App-splash {
  /* Background image */
  background-image: url('./welcome.png');
  width: 100%;
}